import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addTasksComment } from "./addCommentTasks";
import { logEntry } from "../../logEntry";


export async function modifySubTask(activities,state, dispatch, getUser, mainTaskId, handleClose, SUBTASK_MODIFY, SUBTASK, SUBTASK_DESCRIPTION, ACCEPTANCE_CRITERIA) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    try {
      const start_time=Date.now();
      var response = await API.post("manage_tasks.php", {
        assignedBy: getUser.empId,
        subtaskId: state.subTaskId.value,
        "projectId":getUser.projectId,
        crop: getUser.corp,
        action: "modify",
        maintaskId: mainTaskId,
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        empId: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        assignedTo: state.userSelected === "" ? state.id.value : state.userSelected,
        targetDate: state.targetDate.value,
      }, {}, false);
      const end_time=Date.now();
      if (response.status === 'true') {
        // dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        const message =  `${SUBTASK} is modified`; 
        addTasksComment(getUser, state.subTaskId.value, '4', message);
        activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
          sub_activity:"Modify",
          response_time : (end_time-start_time), task_meta_data:{story_id:state.subTaskId.value}
  
        },getUser)
        Alert("success", SUBTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
         //error log
       activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Modify",
      error_message:error.message
    },getUser)
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    let error = "";
    if (state.taskTitle.value === "") {
      error = `${SUBTASK} Title`;
    } else if(state.taskDescription.value === "") {
      error = SUBTASK_DESCRIPTION;
    } else {
      error = ACCEPTANCE_CRITERIA;
    } 
    Alert("warning", `Please give ${error}`);
  }
}