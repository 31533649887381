import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";
import { addCommentUserStory } from "./addCommentUserStory";
import { logEntry } from "../../logEntry";

//For Modify MainTask
export async function updateMainTask(activities,backlogs, activeStatus, currentDate, sprintTargetDate, state, dispatch, getUser, ideaId, moduleId, handleClose, MAINTASK_MODIFY, MODULE, us_id, groupTask, MAINTASK, story_type, group_task_type) {
  dispatch(isLoading());
  if ((state.targetDate.value < currentDate && backlogs === '1') || state.targetDate.value === null) {
    dispatch(isLoaded());
    Alert('warning', 'Select valid target date');

  }
  else if (state.targetDate.value > sprintTargetDate && backlogs === '1') {
    dispatch(isLoaded());
    Alert('warning', `Target Date should not exceed ${MODULE} target date`);
  }
  // else if(state.id.value === null && state.userSelected === ''){
  //   dispatch(isLoaded());
  //   Alert('warning', `Select the Squad Member to assign ${MAINTASK}`);
  // }
  else if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    if (groupTask === true) {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "modifyOnlyGroup",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          targetDate: state.targetDate.value === null ? currentDate : state.targetDate.value,
          module_id: state.moduleId.value,
          idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
          added_to: getUser.empId,
          added_by: getUser.empId,
          mainTaskId: state.taskId.value,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          "projectId": getUser.projectId,
          assignedTo: state.id.value,
          change_assign: state.userSelected,
          activeStatus: activeStatus,
          device_id: state.device_id,
          player_id: state.player_id,
          story_type: 'group',

        }, {}, false);
        if (response.status === 'True') {
          getGroupTaskInfo(activities,state, us_id, getUser, currentDate, activeStatus)
          const message = `This Group ${MAINTASK} is modified`;
          addCommentUserStory(dispatch, getUser, state.taskId.value, message, "09");
          Alert("success", MAINTASK_MODIFY);
        }
        else {
          Alert("warning", "please give all the details");
          dispatch(isLoaded());
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      if (state.userSelected.from === 'group') {
        try {
          let response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "modifyGroup",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            acceptanceCriteria: state.acceptanceCriteria.value,
            targetDate: state.targetDate.value === null ? currentDate : state.targetDate.value,
            module_id: state.moduleId.value,
            idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
            added_to: getUser.empId,
            added_by: getUser.empId,
            mainTaskId: state.taskId.value,
            storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
            "projectId": getUser.projectId,
            assignedTo: state.id.value,
            change_assign: state.userSelected,
            activeStatus: activeStatus,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: 'group',
            group_task_type: group_task_type

          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_MODIFY);
            const message = `This Group ${MAINTASK} is modified`;
            addCommentUserStory(dispatch, getUser, state.taskId.value, message, "09");
            getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, us_id, group_task_type)
          }
          else {
            Alert("warning", "please give all the details");
            dispatch(isLoaded());
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
      } else {
        try {
          const start_time=Date.now();
          let response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "modify",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            acceptanceCriteria: state.acceptanceCriteria.value,
            targetDate: state.targetDate.value === null ? currentDate : state.targetDate.value,
            module_id: state.moduleId.value,
            idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
            added_to: state.userSelected === "" ? state.id.value : state.userSelected.value,
            added_by: getUser.empId,
            mainTaskId: state.taskId.value,
            storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
            "projectId": getUser.projectId,
            assignedTo: state.id.value,
            change_assign: state.userSelected,
            activeStatus: activeStatus,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: story_type,
          }, {}, false);
          const end_time=Date.now();
          if (response.status === 'True') {
            const message = `This ${MAINTASK} is modified`;
            addCommentUserStory(dispatch, getUser, state.taskId.value, message, "09");
            // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
            Alert("success", MAINTASK_MODIFY);
            activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
              sub_activity:"Modify",
              response_time : (end_time-start_time), task_meta_data:{story_id:state.taskId.value}
      
            },getUser)
          }
          else {
            Alert("warning", "please give all the details");
            dispatch(isLoaded());
          }
        } catch (error) {
          Alert('error', error.message);
             //error log
       activities &&  logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Modify",
      error_message:error.message
    },getUser)
          dispatch(isLoaded());

        }
      }
    }
    handleClose()
  } else {
    Alert("warning", "please give all the details")
    dispatch(isLoaded());
  }
}

const getUserIdWithEmails = async (members_email, state, dispatch, getUser, handleClose, us_id, group_task_type) => {

  const emails = members_email.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g);
  // Remove duplicates by converting the array to a Set
  const uniqueEmailsSet = new Set(emails);
  // Convert Set back to array if needed
  const uniqueEmailsArray = [...uniqueEmailsSet];
  const filteredEmails = uniqueEmailsArray.filter(email => email !== getUser.userName);
  try {
    var response = await API.post("get_user.php", {
      action: "get_user_by_email",
      emailArray: filteredEmails,
      "projectId": state.project_id,
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return createGroupMainTask(state, dispatch, getUser, item.empid, parseInt(us_id), group_task_type)
      })
      handleClose()
    }
  } catch (error) {
    console.log(error)
  }
}

export async function createGroupMainTask(state, dispatch, getUser, empId, us_id, group_task_type) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {
      var response = await API.post("manage_userstories.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_to: empId,
        added_by: getUser.empId,
        module_id: state.moduleId.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected === "" ? '0' : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
        idea_id: state.epicSelected,
        "projectId": getUser.projectId,
        device_id: state.device_id,
        player_id: state.player_id,
        story_type: 'group',
        main_task_id: us_id,
        group_task_type: group_task_type

      }, {}, false);
      if (response.status === 'True') {
        console.log("Success")
      }
    } catch (error) {
      console.log("error")
    }
  }

  else {
    console.log("error")
  }
}

async function getGroupTaskInfo(activities,state, us_id, getUser, currentDate, activeStatus) {
  try {
    var response = await API.post("getUpdateSprint.php", {
      story_us_id: us_id,
      action: "get_group_task_info",
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return updateGroupTasks(activities,state, us_id, getUser, item.story_id, currentDate, item.active_status)
      })
    }
  } catch (error) {
    Alert('error', error.message);
  }
}

async function updateGroupTasks(activities,state, us_id, getUser, story_id, currentDate, active_status) {
  try {
    const start_time=Date.now();
  const response=  await API.post("manage_userstories.php", {
      crop: getUser.corp,
      action: "modifyOnlySubGroup",
      title: state.taskTitle.value,
      description: state.taskDescription.value,
      acceptanceCriteria: state.acceptanceCriteria.value,
      targetDate: state.targetDate.value === null ? currentDate : state.targetDate.value,
      module_id: state.moduleId.value,
      idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
      added_to: getUser.empId,
      added_by: getUser.empId,
      mainTaskId: story_id,
      storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
      priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
      "projectId": getUser.projectId,
      assignedTo: state.id.value,
      change_assign: state.userSelected,
      activeStatus: active_status,
      device_id: state.device_id,
      player_id: state.player_id,
      story_type: 'group',
    }, {}, false);
    const end_time=Date.now();
  if (response.status === 'True'){
    activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
      sub_activity:"Modify",
      response_time : (end_time-start_time), task_meta_data:{story_id:story_id}

    },getUser)
  }
  }
   catch (error) {
    Alert('error', error.message);
       //error log
       activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
        sub_activity:"Modify",
        error_message:error.message
      },getUser)
  }
}